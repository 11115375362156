import { Injectable, inject } from '@angular/core';
import { ApiService } from '@core/service/api';
import { Feedback, ParamsFeedback, ParamsFeedbackCreate } from './interface';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'any',
})
export class ApiFeedbackService {
    private _apiService = inject(ApiService);

    list(obj?: ParamsFeedback): Observable<Feedback> {
        return this._apiService
            .get(
                'merch-lk/v1/feedback',
                this._apiService.prepareParams(obj, true)
            )
            .pipe(map((res) => res.json));
    }

    sendMessage(obj: ParamsFeedbackCreate): Observable<void> {
        return this._apiService
            .post('merch-lk/v1/feedback/create', obj)
            .pipe(map((res) => res.json));
    }
}
